import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"

const AboutImg = props => {
  const { src, className = "" } = props
  const data = useStaticQuery(graphql`
    query {
      sarahSteve: file(relativePath: { eq: "about/sarah-steve.jpeg" }) {
        childImageSharp {
          fluid(maxHeight: 1440, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  if (!data[src]) {
    console.error("image not found:", src)
    //alert(src)
  }

  //   const eagerImages = ["home1"]
  const loading = "lazy"

  return (
    <GatsbyImage
      {...props}
      fluid={data[src].childImageSharp.fluid}
      className={`img img--${src} ${className}`}
      loading={loading}
    />
  )
}
export default AboutImg
