/*

useParallaxItems

    .parallax-trigger
        The trigger element for scrollTrigger


    .parallax-transfrom
        The element which the parallax transforms are applied to


*/

import { useEffect } from "react"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/all"

const useParallaxItems = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    // PARALAX
    const parallaxItems = gsap.utils.toArray(".parallax-trigger")

    parallaxItems.forEach(parallaxItem => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: parallaxItem,
          start: "top bottom", // when the top of the trigger hits the top of the viewport
          end: "bottom top", // end after scrolling 500px beyond the start
          scrub: 2, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
        },
        defaults: {
          ease: "linear",
        },
      })
      // add animations and labels to the timeline
      tl.addLabel("start").fromTo(
        parallaxItem.querySelector(".parallax-transform"),
        {
          scale: 1.1,
          y: "6%",
        },
        {
          scale: 1.1,

          y: "-6%",
        }
      )
    })
  }, [])
}

export default useParallaxItems
