import gsap from "gsap"
import React from "react"
import { useEffect } from "react"
import Image from "../../assets/images/Image"

import { selectAll, select } from "../../utils/helpers"

const useHomeHeroAnimation = () => {
  useEffect(() => {
    const headlines = selectAll(".home-hero__text-container span")
    const heroImg1Container = select(".home-hero__image-1-container")
    const heroImg1 = select(".home-hero__image-1-container .img")
    console.log(headlines)

    const tl = gsap.timeline({
      defaults: {
        ease: "power3.inOut",
        duration: 2,
      },
    })
    tl.add("start")
      .set(".home-hero", { visibility: "visible" })
      .fromTo(
        heroImg1Container,
        { clipPath: "inset(0% 0% 0% 100%)" },
        { clipPath: "inset(0% 0% 0% 0%)", duration: 2.8 },
        "start+=0.2"
      )
      .fromTo(
        heroImg1,
        { scale: 1.3 },
        { scale: 1.1, duration: 2.8 },
        "start+=0.2"
      )
      .fromTo(
        headlines,
        { clipPath: "inset(0% 0% 0% 100%)" },
        { clipPath: "inset(0% 0% 0% 0%)", duration: 1.6, stagger: 0.3 },
        "<1.2"
      )
  }, [])
}

const HomeHeroSection = ({ pageData }) => {
  useHomeHeroAnimation()

  return (
    <section className="home-hero" style={{ visibility: "hidden" }}>
      <div className="container container--ml">
        <div className="flex flex-col">
          <div className="home-hero__text-container">
            <h2>
              <span className="smaller">Revitalising Houses with </span>
              <span>Sublime </span>
              <span>Quality</span>
            </h2>
          </div>
          <div className="home-hero__image-1-container flex-grow parallax-trigger">
            <div className="parallax-transform">
              <Image
                pageData={pageData}
                src="cliftonLounge1After"
                loading="eager"
                imgStyle={{
                  objectPosition: "bottom",
                }}
              />
            </div>
          </div>
        </div>

        <div className="home-hero__intro-text">
          <h3>What we do.</h3>
          <div>
            <span> At </span>
            <h1 className="p-default">Stella Renovations</h1>{" "}
            <p>
              we specialise in transforming and revitalising old existing spaces
              into beautiful high functioning homes with strength and
              personality. We are based in Christchurch, New Zealand.
            </p>
          </div>
        </div>
      </div>
      <div className="container container--mr">
        <div className="home-hero__image-2-container parallax-trigger">
          <div className="parallax-transform">
            <Image
              pageData={pageData}
              src="cliftonLounge2After"
              imgStyle={{
                objectPosition: "bottom",
              }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeHeroSection
