import React from "react"
import AboutImg from "../../assets/images/about"
import AnimationLink from "../AnimationLink"

const AboutSection = () => {
  return (
    <section className="home-about mb-32" id="about">
      <div className="container mx-auto " style={{ maxWidth: "72rem" }}>
        <div className="home-about__text">
          <h2>About Stella Renovation</h2>
          <p className="home-about__paragraph ">
            In 2017, we created Stella Renovations in pursuit of a more
            fulfilling life - working for ourselves and doing something we
            loved. <br />
            <br />
            Fast-forward to today and we have transformed loads of properties,
            every time collecting and learning new skills and techniques. We
            then get to pour all that hard-earned knowledge in the next exciting
            projects. The more we do the more passionate we are at transforming
            spaces into beautiful high functioning homes with personality!
          </p>
        </div>
        <div className="parallax-trigger home-about__image ">
          <AboutImg src="sarahSteve" className="parallax-transform" />
        </div>

        {/* <AnimationLink to="/about-us/">
          <div className="home-about__cta">More about Stella</div>
        </AnimationLink> */}
      </div>
    </section>
  )
}

export default AboutSection
