import React from "react"

const ContactSection = () => {
  return (
    <section className="home-contact">
      <div
        className="container mx-auto text-center"
        style={{ maxWidth: "72rem" }}
      >
        <h2>Contact us</h2>
        <p className="home-contact__paragraph">
          We are interested in any homes we can potentially add value to, no
          matter the state. <br />
          <br />
          Please email us if you would like to discuss selling your home hassle
          free.
        </p>{" "}
        <a
          href="mailto:steveparratt@gmail.com"
          className="home-contact__cta"
          target="_blank"
        >
          Email us
        </a>
      </div>
    </section>
  )
}

export default ContactSection
