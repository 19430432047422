import React, { useEffect, useState } from "react"

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  Controller,
} from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import gsap from "gsap"

import "swiper/swiper.scss"

import "swiper/components/pagination/pagination.scss"
import "swiper/components/scrollbar/scrollbar.scss"

import "./SectionProjects.scss"
import AnimationLink from "../AnimationLink"
import { projectNavItems } from "../projects/projectsNavItems"

import Image from "../../assets/images/Image"

SwiperCore.use([Controller, Pagination, Scrollbar])

gsap.defaults({ duration: 0.4, ease: "power2.out" })

const SWIPER_BREAKPOINTS = {
  0: {
    spaceBetween: 15,
    slidesPerView: 1.4,
  },
  640: {
    spaceBetween: 30,
  },
  768: {
    spaceBetween: 60,
  },
  1024: {
    spaceBetween: 75,
    slidesPerView: 2,
  },
  1280: {
    spaceBetween: 90,
  },
  1440: {
    spaceBetween: 100,
  },
  1920: {
    spaceBetween: 160,
  },
}

const ProjectsSlider = ({ pageData }) => {
  //   const swiper = useRef(null)
  const [controlledSwiper, setControlledSwiper] = useState(null)
  const handleInit = () => {
    console.log("handleInit")
    gsap.set(".swiper-slide-next .slide__name h2", {
      y: "100%",
    })
    gsap.set(".swiper-slide-prev .slide__name h2", {
      y: "100%",
    })

    gsap.set(".swiper-slide-next .slide__cta", {
      y: "100%",
      opacity: 0,
    })
    gsap.set(".swiper-slide-prev .slide__cta", {
      y: "100%",
      opacity: 0,
    })

    gsap.set(".swiper-slide", {
      scale: 0.85,
    })

    gsap.set(".swiper-slide-active", {
      scale: 1,
    })
  }

  const handleSlideChange = () => {
    console.log("handleSlideChange")
    gsap.to(".slide__name h2", { y: "100%" })
    gsap.to(".slide__cta", { y: "100%", opacity: 0 })

    gsap.to(".swiper-slide-active", {
      scale: 0.85,
    })
  }

  const handleSlideChangeTransitionEnd = () => {
    console.log("handleSlideChangeTransitionEnd")
    gsap.to(".swiper-slide-active", {
      scale: 1,
    })

    gsap.to(".swiper-slide-active .slide__name h2", {
      y: "0%",
      duration: 0.4,
      delay: 0.2,
      ease: "power2.out",
    })
    gsap.to(".swiper-slide-active .slide__cta", {
      y: "0%",
      opacity: 1,
      duration: 0.4,
      delay: 0.4,
      ease: "power2.out",
    })
  }

  useEffect(() => {
    if (!controlledSwiper) return
    controlledSwiper.slideTo(0)
  }, [controlledSwiper])

  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={2}
      centeredSlides={true}
      //   loop={true}
      speed={400}
      breakpoints={SWIPER_BREAKPOINTS}
      navigation={{
        prevEl: ".prev-slide-btn",
        nextEl: ".next-slide-btn",
      }}
      onSwiper={setControlledSwiper}
      pagination={{ clickable: true }}
      onSlideChange={() => handleSlideChange()}
      onSlideChangeTransitionEnd={() => handleSlideChangeTransitionEnd()}
      onInit={() => handleInit()}
    >
      {projectNavItems.map((project, index) => (
        <SwiperSlide key={index} className="slide parallax-trigger">
          {({ isPrev, isNext }) => (
            <div
              onClick={() => {
                if (isPrev) controlledSwiper.slidePrev()
                if (isNext) controlledSwiper.slideNext()
              }}
            >
              <div className="overflow-hidden">
                <Image
                  pageData={pageData}
                  src={project.src}
                  //   loading="eager"
                  className="slide__image parallax-transform"
                  imgStyle={{ objectPosition: "bottom" }}
                />
              </div>

              <div className="slide__name">
                <h2> {project.title}</h2>
              </div>
              <AnimationLink to={project.href}>
                <div className="slide__cta">View project</div>
              </AnimationLink>
            </div>
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

const ProjectsSection = ({ pageData }) => {
  return (
    <section className="home-projects">
      <div className="container container--ml">
        <h2>Featured Projects</h2>
        <p>
          These are some of our showcase projects with the largest
          transformations.
        </p>
      </div>
      <ProjectsSlider pageData={pageData} />
    </section>
  )
}

export default ProjectsSection
