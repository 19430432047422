import React from "react"

import "./index.scss"

import SEO from "../../components/seo"

import SectionHero from "./SectionHero"
import SectionProjects from "./SectionProjects"
import SectionAbout from "./SectionAbout"
import SectionContact from "./SectionContact"
import useExitAnimationFade from "../../animations/useExitAnimationFade"
import useParallaxItems from "../../animations/useParallaxItems"

const IndexPage = ({ data: pageData }) => {
  useParallaxItems()
  useExitAnimationFade()

  console.log(pageData)

  return (
    <div className="page home">
      <SEO
        title="Home"
        description="At Stella Renovations we specialise in transforming and revitalising old existing spaces into beautiful high functioning homes with strength and personality. We are based in Christchurch, New Zealand."
      />
      <SectionHero pageData={pageData} />
      <SectionProjects pageData={pageData} />
      <SectionAbout />
      <SectionContact />
    </div>
  )
}
export default IndexPage
