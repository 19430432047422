import { graphql } from "gatsby"

import HomePage from "../components/home/index"

export default HomePage

export const query = graphql`
  query HomeImages {
    cliftonLounge1After: file(
      relativePath: { eq: "projects/clifton/lounge 1 after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 1440, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cliftonLounge2After: file(
      relativePath: { eq: "projects/clifton/lounge 1.5 after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 1440, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cliftonExterior3After: file(
      relativePath: { eq: "projects/clifton/exterior 2 after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cliftonExterior4After: file(
      relativePath: { eq: "projects/clifton/exterior after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    exterior4After: file(
      relativePath: { eq: "projects/clifton/exterior 2 after.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 1440, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    sunvaleIntro2After: file(
      relativePath: { eq: "projects/sunvale/intro2After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    moncksSpurExterior1After: file(
      relativePath: { eq: "projects/moncks-spur/exterior1After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    landsdowneExterior1After: file(
      relativePath: { eq: "projects/landsdowne/exterior1After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    landsdowneExterior4After: file(
      relativePath: { eq: "projects/landsdowne/exterior4After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    solearesExterior1After: file(
      relativePath: { eq: "projects/soleares/exterior1After.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
